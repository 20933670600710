
<template>
  <div class="My">
    <div class="contentDiv flexLR">
      <div
        @click="$router.push('/My/Setting')"
        class="flexLR"
        style="width: 90%; justify-content: start"
      >
        <div>
          <img
            style="display: block; margin-right: 10px"
            src="../../../assets/mobileImg/个人信息.png"
            alt=""
          />
        </div>
        <div>个人信息</div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="contentDiv flexLR">
      <div
        @click="$router.push('/My/Administration')"
        class="flexLR"
        style="width: 90%; justify-content: start"
      >
        <div>
          <img
            style="display: block; margin-right: 10px"
            src="../../../assets/mobileImg/帮助中心.png"
            alt=""
          />
        </div>
        <div>帮助中心</div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="contentDiv flexLR">
      <div
        @click="$router.push('/My/UpdatePassword')"
        class="flexLR"
        style="width: 90%; justify-content: start"
      >
        <div>
          <img
            style="display: block; margin-right: 10px"
            src="../../../assets/mobileImg/修改密码.png"
            alt=""
          />
        </div>
        <div>修改密码</div>
      </div>
      <van-icon name="arrow" />
    </div>
    <div style="margin: 20px auto; width: 100%">
      <van-button type="info" style="width: 100%" round @click="out"
        >退出登录</van-button
      >
    </div>
    <div style="height: 50px"></div>
    <van-popup v-model="cutShow" round position="bottom">
      <van-picker
        show-toolbar
        title="切换单位"
        :columns="columns"
        value-key="schoolName"
        @confirm="onConfirm"
        @cancel="cutShow = false"
        :default-index="actionIndex"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "my",
  components: {},
  data() {
    return {
      cutShow: false,
      actionIndex: 0,
      show: false,
      columns: [],
      schoolName: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    out() {
      this.$store.state.token = "";
      this.$store.state.uid = "";
      this.$store.state.qid = "";
      this.$store.state.pid = "";
      localStorage.setItem("isLogin", false);
      this.$router.replace("/");
    },
    onConfirm(val) {},
    async getSchoolList() {},
    updateData() {
      this.$emit("updateLoading");
      this.getSchoolList();
    },
  },
  created() {
    this.getSchoolList();
  },
  mounted() {},
};
</script>
<style lang='scss' scoped>
.My {
  padding: 0 20px;
  padding-top: 120px;
  .contentDiv {
    height: 100px;
    padding: 20px 40px;
    background-color: white;
  }
}
</style>